<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex align-items-center justify-center pb-1">
          <feather-icon icon="UserIcon" size="16" />
          <h4 class="pl-1" style="padding-top: 8px;">
            {{ $t('general_info') }}
          </h4>
        </div>
        <validation-observer ref="groupValidation">
          <b-row>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="group.name"
                :label="$t('group')"
              ></vi-input
            ></b-col>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="group.code"
                :label="$t('code')"
              ></vi-input>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button @click="confirm" variant="primary">{{ $t('save') }}</b-button>
      </div>
    </b-card>
    <permissions
      v-if="$route.params.id"
      actionGet="devices/GET_DEVICES_WITH_GROUP_ABILITES"
      actionSave="devices/SAVE_DEVICE_GROUP_ABILITES"
    ></permissions>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue';
import ViInput from '@/views/components/ViInput.vue';
import Permissions from './Permissions.vue';
import ViSelect from '../../components/ViSelect.vue';
import { ValidationObserver } from 'vee-validate';
import { onMounted, watch, ref } from '@vue/composition-api';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    Permissions,
    ViSelect,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  setup(props, context) {
    const toastInstance = useToast();

    const groupId = context.root.$route.params.id;

    const groupValidation = ref(null);

    const group = ref({
      code: null,
      companyId: context.root.$route.params.companyId,
    });

    onMounted(async () => {
      if (groupId) {
        const response = await store.dispatch('groups/GET_GROUP', groupId);
        group.value = response.data;
      }
    });

    const confirm = async () => {
      const validation = await groupValidation.value.validate();
      if (groupId || validation) {
        if (
          group.value.password &&
          group.value.password != group.value.repeatPassword
        ) {
          dangerToast(
            toastInstance,
            i18n.t('warning'),
            i18n.t('profile.no_matching_password'),
          );
        } else {
          let { password, repeatPassword, ...u } = group.value;
          if (group.value.password) u.password = group.value.password;

          const response = await store.dispatch(
            groupId ? 'groups/UPDATE_GROUP' : 'groups/CREATE_GROUP',
            {
              groupId,
              group: u,
            },
          );
          if (response) {
            successToast(
              toastInstance,
              i18n.t('success'),
              groupId ? i18n.t('group_updated') : i18n.t('group_created'),
            );
            context.root.$router.push({ name: 'groups' });
          } else {
            dangerToast(
              toastInstance,
              i18n.t('failed'),
              groupId
                ? i18n.t('profile.failed')
                : i18n.t('group_creation_failed'),
            );
          }
        }
      }
    };

    return { group, groupId, confirm, groupValidation };
  },
};
</script>

<style></style>
